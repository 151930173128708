import React, { lazy } from "react"

// project-imports
import GuestGuard from "utils/route-guard/GuestGuard"
import Loadable from "components/Loadable"
import SharedCommonLayout from "../layout/shared/CommonLayout"
import RedirectToGoogleDrive from "pages/shared/simena/landing/RedirectToGoogleDrive"

const LandingPage = Loadable(lazy(() => import("pages/shared/simena/landing/LandingPage")))

// ==============================|| AUTH ROUTES ||============================== //

const SharedLoginRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <GuestGuard prefix="/">
          <SharedCommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: "",
          element: <LandingPage />
        },
        {
          path: "/showreel",
          element: <RedirectToGoogleDrive />
        }
      ]
    }
  ]
}

export default SharedLoginRoutes
