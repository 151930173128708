import { useEffect } from "react"

const RedirectToGoogleDrive = () => {

  useEffect(() => {
    window.location.href = "https://drive.google.com/drive/folders/1a8v6eB_GUX0MrmOEAYeE7o8HvSXsmKoR"
  }, [])

  return null
}

export default RedirectToGoogleDrive
